import Ship from '../../assets/graphics/ship.svg'
import "./Services.css";

function Seaways() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Sea-ways</h1>
                    <p className="home-text">
                    <strong>Full container load (FCL)</strong> <br />
                    Our Team with the years of enriched experience in freight forwarding, offers inbound and outbound full
                    container load (FCL ) services. We have exclusive worldwide service contracts with premium carriers that
                    enable us to offer guaranteed space, competitive rates and definite sailings schedule. We carry expertise in
                    handling large volumes on global basis. <br /><br />
                    <strong>Less than container load (LCL) Consolidation</strong> <br />
                    Whenever you don't need an entire ocean container, we consolidate several shipments in one container.
                    Sophisticated systems allow us to effectively manage our global hub & spoke network. Seamlessly Integrated
                    pickup & delivery services make this product reliable as well as cost effective.
                    We offer Inbound and Outbound LCL consolidation from - to worldwide major seaports / dry ports. In our
                    endeavor we are offering fixed day direct sailings from majority of origins. We strive to maintain time bound
                    and cost effective deliveries. Our overseas associates are carefully chosen after ensuring that they also share
                    the commensurate services. <br /> <br />
                    <strong>Reefer Containers</strong> <br />
                    To prolong the practical shelf life - the time from harvest until the product loses its commercial value - for
                    example fruits/vegetables/sea food/meat, we offers a wide range of refrigerated services with the help of
                    world-wide contract with the shipping lines. We know our customers' requirements of with Controlled
                    Atmosphere, Dehumidification, Cold Treatment and Ultra Low Temperature. </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Ship} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default Seaways;