import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItems } from './CarouselItems';
import "./Carousel.css"

function CarouselSlider() {
    return (  
      <Carousel fade={true} pause={false} indicators={false}>
        {CarouselItems.map((item, index) => {
          return(
            <Carousel.Item interval={4000} key={index}>
              <img
                className="d-block w-100"
                src={item.image}
                alt={item.alt}
              />
              <Carousel.Caption className="carousel-caption-cs">
                <h1>{item.caption.title}</h1>
                <h4>{item.caption.text}</h4>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    );
}

export default CarouselSlider;