import Box from '../../assets/graphics/box.svg'
import "./Services.css";

function ProjectLogistics() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Project Logistics Management</h1>
                    <p className="home-text">
                    In project logistics management, we are specialist in the management of projects with complex
                    logistics needs We undertakes and executes turnkey logistics for project cargo comprising of overdimensional (ODC) and over-weight cargoes (OWC) mainly in the steel, cement, oil, power and
                    telecom sectors. These projects, executed on a turnkey basis, with our worldwide network of
                    associates consisting of vessel owners, freight forwarders and transport equipment owners. <br /> <br />
                    The scope of work begins from packaging of the over-dimensional, over-weight, containerized, air
                    cargo and general cargo at the factory point anywhere in the world, to delivery of the same at the
                    project site in India or anywhere in the world, involving Ocean / Sea / River Transportation ,Super
                    Cargo Services, Customs Clearance and Project Registration, Route Survey, Inland Transportations etc.
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Box} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default ProjectLogistics;