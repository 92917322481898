import { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import "./Enquiry.css";
import jainam from "../../api/jainam";
function Enquiry() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [body, setBody] = useState();
    const [loading, setLoading] = useState(false);
    const inputStyle = {width:"98%",height: "40px"}
    const messageTimeOut = () => {
        setTimeout(function(){
            setMessage("");
        },5000)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let msg = {}
        if(name && email && body && subject){
            let reqBody = {name, email, subject, body}
            if(phone){
                reqBody.phone = phone;
            }
            try{
                setLoading(true);
                let response = await jainam.post("enquiry", reqBody);
                setLoading(false);
                if(response.data && response.data.sent){
                    msg = {
                        type: "success",
                        text: "Enquiry Sent!"
                    }
                    setMessage(msg);
                    messageTimeOut()
                    setName("");
                    setEmail("");
                    setSubject("");
                    setPhone("");
                    setBody("");
                }else{
                    msg = {
                        type: "error",
                        text: "Something went wrong please try again later"
                    }
                    setMessage(msg);
                    messageTimeOut()
                }
            }catch(err){
                setLoading(false);
                msg = {
                    type: "error",
                    text: "Something went wrong please try again later"
                }
                setMessage(msg);
                messageTimeOut()
            }
        }else{
            if(!name){
                msg = {
                    type: "error",
                    text: "Please enter your name"
                }
            } else if(!email){
                msg = {
                    type: "error",
                    text: "Please enter your email"
                }
            } else if(!subject){
                msg = {
                    type: "error",
                    text: "Please enter your subject"
                }
            } else if(!body){
                msg = {
                    type: "error",
                    text: "Please enter your message"
                }
            }
            
            setMessage(msg);
            messageTimeOut()
        }
    }



    return (  
        <div className="main-container-enquiry">
            <div className="enquiry-title-container">
                <h1 className="home-title">Enquiry</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <div className="enquiry-col-50">
                        <label htmlFor="name">Name</label>
                        <Input type="name" id="name" placeholder="Name" value={name} onChange={e => setName(e.target.value)} style={inputStyle}/>    
                    </div>
                    <div className="enquiry-col-50-right">
                        <label htmlFor="email">Email</label>
                        <Input type="email" id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} style={inputStyle}/>    
                    </div>
                </div>
                <div className="input-container">
                    <div className="enquiry-col-50">
                        <label htmlFor="subject">Subject</label>
                        <Input type="text" id="subject" placeholder="Subject" value={subject} onChange={e => setSubject(e.target.value)} style={inputStyle}/>    
                    </div>
                    <div className="enquiry-col-50-right">
                        <label htmlFor="phone">Phone</label>
                        <Input type="tel" id="phone" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} style={inputStyle}/>
                    </div>
                </div>
                <textarea className="enquiry-textbox" name="message" placeholder="Message" value={body} rows="5" onChange={e => setBody(e.target.value)} style={{width: "99%"}}></textarea>
                <div className="enquiry-submit">
                    <Button loading={loading}>Submit</Button>
                </div>
                {(message && message.text) && (<div className="enquiry-message-container">
                    <h5 style={(message.type && message.type === "error") ? {color: "red"} : {color: "green"}}>{message.text}</h5>
                </div>)}
            </form>
        </div>
    );
}

export default Enquiry;