export const MenuItems = [
    {
        title: 'HOME',
        url: '/'
    },
    {
        title: 'ABOUT US',
        url: '/aboutus'
    },
    {
        title: 'SERVICES',
        subItems: [
            {
                title: 'Sea-Ways Freight',
                url: '/services/seaways'
            },
            {
                title: 'Air-Ways Freight',
                url: '/services/airways'
            },
            {
                title: 'Road-Ways Freight',
                url: '/services/roadways'
            },
            {
                title: 'Customs House Agency Management',
                url: '/services/customs'
            },
            {
                title: 'Supply Chain Management',
                url: '/services/supplychain'
            },
            {
                title: 'Project Logistics Management',
                url: '/services/projectlogistics'
            },
            {
                title: 'Over Dimensional Cargo',
                url: '/services/odc'
            },
            {
                title: 'Vessel Chartering & Brokerage',
                url: '/services/vesselchartering'
            },
            {
                title: 'Our Services',
                url: '/services/ourservices'
            },
            {
                title: 'Outsourced Services',
                url: '/services/outsourceservices'
            }
        ],
        url: '#'
    },
    {
        title: 'ENQUIRY',
        url: '/enquiry'
    },
    {
        title: 'CONTACT',
        url: '/contact'
    }
]