import CarouselSlider from '../../components/Carousel/Carousel';
import "./Home.css";
import About from '../../assets/graphics/team.svg';
import Aircraft from '../../assets/graphics/aircraft.svg';
import Navigator from '../../assets/graphics/navigator.svg';
import Ship from '../../assets/graphics/ship.svg';
import Factory from '../../assets/graphics/factory.svg';
// import Clients from '../../assets/graphics/clients.svg'; 
import Button from "../../components/Button/Button";
import Card from 'react-bootstrap/Card';
// import airplane1 from '../../assets/images/airplan1.jpg';
// import airplane2 from '../../assets/images/airplan2.jpg';
// import cargo from '../../assets/images/cargo.jpg';
// import container1 from '../../assets/images/container1.jpg';
// import container2 from '../../assets/images/container2.jpg';
// import container3 from '../../assets/images/container3.jpg';
// import container4 from '../../assets/images/container4.jpg';
// import train from '../../assets/images/train.jpg';
import { useNavigate } from 'react-router';

function Home() {
    let navigate = useNavigate();
    return (
        <>
            <CarouselSlider />
            <div className="home-tag">
                <h2>Logistics Servicing for the you that never stops moving!</h2>
            </div>
            <div className="home-components">
                <section className="home-about">
                    <div className="home-col-50">
                        <h1 className="home-title">About Us</h1>
                        <p className="home-text">Jainam Logistic Solutions came into existence in 2020. We provides comprehensive multimodal logistics
                        solutions spanning International Freight by AIR & SEA for inbound or outbound shipments with last mile
                        connectivity aided by a large scale network at multi-locations. <br/>
                        Jainam Logistic Solutions leverages the advantages of integrated logistics solutions that are seamless,
                        efficient, effective and more. Our client- centric focus & innovation solutions are most important resource to
                        deliver our service commitment. <br />
                        Jainam Logistic Solutions serving as one stop logistics global solution provider to its customers, a dedicated
                        team of more than 200 diversified logistics professionals will develop the right solution for your needs and
                        will manage process that will maximize value along with high quality and time defined logistics from end-to
                        -end. Our interactive world className IT set up enables our customers & associates to access on-line & real-time information.</p>
                        <Button buttonStyle="btn--primary" buttonSize="btn--medium" onClick={() => navigate("/aboutus")}>Read More</Button>
                    </div>
                    <div className="home-col-50 about-image-container">
                        <img src={About} alt="about" className="about-home-image"/>
                    </div>
                </section>
                <section >
                    <div className="service-title-container">
                        <h1 className="home-title">Our Services</h1>
                    </div>
                    <div className="home-services">
                        <div className="home-col-28">
                            <Card className="service-card">
                                <div className="service-title-container">
                                    <Card.Img variant="top" src={Aircraft} style={{width:'75%'}}/>
                                </div>
                              <Card.Body>
                                <Card.Title className="home-title"><h1 style={{textAlign: "center"}}>Air-ways</h1></Card.Title>
                                <Card.Text className="home-text" style={{textAlign: "center"}}>
                                We have an experienced team of Air Freight specialists; all are skilled in delivering your
                                daily priorities on time, confirmed space, fast frequency and competitive cost. We listen,
                                learn and form a close partnership with you to identify your needs and understand your
                                goals. Our standard Air Freight products and their customizable options bring added
                                flexibility to your supply chain management.
                                </Card.Text>
                                <div className="service-title-container">
                                    <Button buttonStyle="btn--primary" buttonSize="btn--small" onClick={() => navigate("/services/airways")}>Read More</Button>
                                </div>

                              </Card.Body>
                            </Card>
                        </div>
                        <div className="home-col-28">
                            <Card className="service-card">
                                <div className="service-title-container">
                                    <Card.Img variant="top" src={Ship} style={{width:'75%'}}/>
                                </div>
                              <Card.Body>
                                <Card.Title className="home-title"><h1 style={{textAlign: "center"}}>Sea-ways</h1></Card.Title>
                                <Card.Text className="home-text" style={{textAlign: "center"}}>
                                Our Team with the years of enriched experience in freight forwarding, offers inbound and outbound full
                                container load (FCL ) services. We have exclusive worldwide service contracts with premium carriers that
                                enable us to offer guaranteed space, competitive rates and definite sailings schedule. We carry expertise in
                                handling large volumes on global basis.
                                </Card.Text>
                                <div className="service-title-container">
                                    <Button buttonStyle="btn--primary" buttonSize="btn--small" onClick={() => navigate("/services/seaways")}>Read More</Button>
                                </div>

                              </Card.Body>
                            </Card>
                        </div>
                        <div className="home-col-28">
                            <Card className="service-card">
                                <div className="service-title-container">
                                    <Card.Img variant="top" src={Navigator} style={{width:'75%'}}/>
                                </div>
                              <Card.Body>
                                <Card.Title className="home-title"><h1 style={{textAlign: "center"}}>Road-ways</h1></Card.Title>
                                <Card.Text className="home-text" style={{textAlign: "center"}}>
                                We are your trusted Road Freight partners who know as much about your business goals as
                                transportation issues in your states. Through clear communication, thoughtful collaboration and
                                dedicated follow-through, our Road-ways Freight management teams seek to understand your
                                objectives and create solutions that deliver. We combine simple, standardized Road Freight products
                                with a host of customizable options to achieve the ideal balance of lead-time, capacity, frequency and
                                cost
                                </Card.Text>
                                <div className="service-title-container">
                                    <Button buttonStyle="btn--primary" buttonSize="btn--small" onClick={() => navigate("/services/roadways")}>Read More</Button>
                                </div>
                              </Card.Body>
                            </Card>
                        </div>
                    </div>
                </section>
                <section className="home-industries">
                    <div className="service-title-container">
                        <img src={Factory} alt="" className="about-home-image"/>
                    </div>
                    <div className="service-title-container">
                        <h1 className="home-title">Industries we Serve</h1>
                    </div>
                    <div className="home-services">
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-university industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Banking/Finance Sector</p>
                            </div>
                        </div>
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-building industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Corporate</p>
                            </div>
                        </div>
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-book-reader industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Educational Institute</p>
                            </div>
                        </div>
                    </div>
                    <div className="home-services">
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-first-aid industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Pharma</p>
                            </div>
                        </div>
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-store industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Retail Industry</p>
                            </div>
                        </div>
                        <div className="home-col-28">
                            <div className="each-industry">
                                <i className="fas fa-gavel industry-icons"></i>
                                <p className="home-text" style={{marginBottom: 0}}>Govt. Public Sector</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="home-clients">
                    <div className="service-title-container">
                        <img src={Clients} alt="" className="about-home-image"/>
                    </div>
                    <div className="service-title-container">
                        <h2 className="home-title">Clients</h2>
                    </div>
                    <div className="container-fluid padding" style={{paddingTop: "30px"}}>
                        <div className="row clientscontainer">
                            <div className="col-12 text-center">
                                <div className="Smain">
                                    <div className="slidermain">
                                        <div>
                                            <img className="logo-client" alt="cl1" src={airplane1}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={airplane2}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={cargo}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={container1}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={container2}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={container3}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={container4}/>
                                        </div>
                                        <div>
                                            <img className="logo-client" alt="cl1" src={train}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                </section> */}
            </div>  
        </>
        
        
    );
}

export default Home;