import Road from '../../assets/graphics/navigator.svg'
import "./Services.css";

function Roadways() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Road-ways</h1>
                    <p className="home-text">
                    We are your trusted Road Freight partners who know as much about your business goals as
                    transportation issues in your states. Through clear communication, thoughtful collaboration and
                    dedicated follow-through, our Road-ways Freight management teams seek to understand your
                    objectives and create solutions that deliver. We combine simple, standardized Road Freight products
                    with a host of customizable options to achieve the ideal balance of lead-time, capacity, frequency and
                    cost. Whether it means providing same day delivery or hauling hazardous cargo, our freight
                    specialists know the key to a successful supply chain is a committed relationship with our customer. <br /> <br />
                    Our comprehensive menu of road transport solutions provides you with the resources, expertise and
                    assets to effectively tackle your most complex transportation and logistics challenges. Assisted by a
                    team of expert professionals, we are able to meet the deadlines and render our clients exceptional
                    services. Our expertise lies in offering timely, accurate and capable performance road service all
                    across the nation. Being a client centric organization, we always ensure customer satisfaction and
                    therefore, all the consignments are handled by us with great care. This further ensures that the items
                    remain damage free and safe and during the transit.
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Road} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default Roadways;