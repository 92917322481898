import Delivered from '../../assets/graphics/delivered.svg'
import "./Services.css";

function VesselChartering() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Vessel Chartering & Brokerage Services</h1>
                    <p className="home-text">
                    <strong>Heavy Lift & Project Cargo Services</strong> <br />
                    Projects may involve transporting a complete plant to a remote construction site. Our Project & heavy lift
                    experts are handling across the globe, heavy-lift transport, charters nd deliveries. The service starts with
                    assessment and consultancy on every aspect of a cargo movement and finishes with delivery. It includes
                    Execution, coordination and supervision, Import process evaluation and customs clearance etc. Door to door
                    delivery transport of heavy lifts is all done in a very professional & thus reliable manner. No matters, how big
                    the volume, dimension and weight of your heavy lift, we promise the safe & secured delivery for your projects
                    of steel, power, cement, heavy engineering etc. <br /> <br />
                    <strong>Break Bulk Cargo Services</strong> <br />
                    Our dedicated Break Bulk team has the experience and knowledge to help move your cargo professionally,
                    with reliability, and in the most cost efficient way. Our experts understand the intricacies of handling this
                    cargo and are involved in the pricing and acceptance of all Break Bulk requests. We are specialized to handle
                    the projects from anywhere to anywhere in the World through our worldwide network of agents. We work in
                    close synch with vessel owners and our clients to understand their specific requirements and execute the
                    services accordingly. We are carrying structural / fabricated steel cargo, machinery items, steel coils, steel
                    plates, steel sheets, pipes, granites etc. <br /> <br />
                    <strong>Dry Bulk Cargo Services</strong>  <br />
                    We have expert team deals in to arrange Dry bulk vessels as bareboat charter, time charter, voyage charter,
                    spot charter from anywhere in the world to your desired ports for Iron ore, coking coal, steel products, steam
                    coal, grain commodities, forest products, mineral concentrates and other loose commodities.of steel, power,
                    cement, heavy engineering etc.
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Delivered} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default VesselChartering;