import About from '../../assets/graphics/team.svg'
import Vission from '../../assets/graphics/vission.svg'
import Mission from '../../assets/graphics/mission.svg'
import Approach from '../../assets/graphics/approach.svg'
import "./AboutUs.css";
function AboutUs() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">About Us</h1>
                    <p className="home-text">Jainam Logistic Solutions came into existence in 2020. We provides comprehensive multimodal logistics
                    solutions spanning International Freight by AIR & SEA for inbound or outbound shipments with last mile
                    connectivity aided by a large scale network at multi-locations. <br/>
                    Jainam Logistic Solutions leverages the advantages of integrated logistics solutions that are seamless,
                    efficient, effective and more. Our client- centric focus & innovation solutions are most important resource to
                    deliver our service commitment. <br />
                    Jainam Logistic Solutions serving as one stop logistics global solution provider to its customers, a dedicated
                    team of more than 200 diversified logistics professionals will develop the right solution for your needs and
                    will manage process that will maximize value along with high quality and time defined logistics from end-to
                    -end. Our interactive world className IT set up enables our customers & associates to access on-line & real-time information.</p>
                    
                </div>
                <div className="container-col-50 content-container1">
                    <img src={About} alt="about" className="container-image"/>
                </div>
            </section>
            <section className="container1">
                <div className="container-col-50 ">
                    <img src={Vission} alt="about" className="container-image"/>
                </div>
                <div className="container-col-50 content-container2">
                    <h1 className="home-title">Vission</h1>
                    <p className="home-text">We grow together with your attitude
                    • To be recognized as globalised market leader in Multimodal Logistics. <br />
                    • To be a leading global logistics service provider, offering one stop solutions of all logistics needs, anywhere in the world. <br />
                    • Respected for its High Standard Quality Services, Strong Customer Focus, World Class Systems and Processes, and being
                    a Great Place to Perform.</p>
                </div>
            </section>
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Mission</h1>
                    <p className="home-text">We think globally delivers locally . . .GLOCAL
                    • To enhance our Competitive Edge by offering unique value added quality services embedded with industries' best practices. <br />
                    • To be the most Reliable, Trusted and Preferred logistics partner across Business, providing Cost Effective, Innovative and
                    tailor-made solutions for customers, ultimately enhancing value for its all Stakeholders". <br />
                    • Continuously updating the technological advances to ensure on time real time services to our customers & continuously strive
                    for excellence.</p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Mission} alt="about" className="container-image"/>
                </div>
            </section>
            <section className="container1">
                <div className="container-col-50 ">
                    <img src={Approach} alt="about" className="container-image"/>
                </div>
                <div className="container-col-50 content-container2">
                    <h1 className="home-title">Approach</h1>
                    <p className="home-text">Our reach is global . . . our approach is personal
                    Jainam Logistic Solutions offers not only services to their customers but a commitment. We ensure excellence in all our
                    operations by combining experience, knowledge, planning & execution. The combined efforts of our competent and dedicated
                    professional team reflect in our services level. We will lead from front through high standard of quality services to our all
                    customers and associates world-wide.</p>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;