import airplane1 from '../../assets/images/airplan1.jpg';
import airplane2 from '../../assets/images/airplan2.jpg';
import cargo from '../../assets/images/cargo.jpg';
import container1 from '../../assets/images/container1.jpg';
import container2 from '../../assets/images/container2.jpg';
import container3 from '../../assets/images/container3.jpg';
import container4 from '../../assets/images/container4.jpg';
import train from '../../assets/images/train.jpg';

export const CarouselItems = [
    {
        image: airplane1,
        alt: 'airplane1',
        caption: {
            title: "Choose possibility in every route",
            text: "Move with us"
        },
    },
    {
        image: airplane2,
        alt: 'airplane2',
        caption: {
            title: "Time is money. We save you both",
            text: "Move with us"
        },
    },
    {
        image: cargo,
        alt: 'cargo',
        caption: {
            title: "Let us take the weight off your shoulders",
            text: "Move with us"
        },
    },
    {
        image: container1,
        alt: 'container1',
        caption: {
            title: "Delivered with pride",
            text: "Move with us"
        },
    },
    {
        image: container2,
        alt: 'container2',
        caption: {
            title: "Open the door for a new Direction",
            text: "Move with us"
        },
    },
    {
        image: container3,
        alt: 'container3',
        caption: {
            title: "The logistics company you can rely on",
            text: "Move with us"
        },
    },
    {
        image: container4,
        alt: 'container4',
        caption: {
            title: "Handling everything with heart",
            text: "Move with us"
        },
    },
    {
        image: train,
        alt: 'train',
        caption: {
            title: "Quality that exceeds all expectations",
            text: "Move with us"
        },
    }
    
]