import "./Footer.css"
import { MenuItems } from "../Navbar/MenuItems"
import { NavLink } from "react-router-dom";

function Footer() {
    return (  
        <footer className="footer">
        	<div className="footer-container">
                <div className="row row-border">
                    <div className="footer-col-center" style={{color: "white"}}>
        	 			<h3>Email Us</h3>
                        <h3>Info@jainamlogistics.com</h3>
        	 		</div>
                </div>
        	 	<div className="row">
        	 		<div className="footer-col">
        	 			<h3>Registered and Corporate Office</h3>
                        <h4>Office No 707, Prabhat Center Annexe, <br/>
                        Sector 1A, CBD Belapur, Navi Mumbai 400614 <br/>
                        GST Number: 27AAECJ8280C1ZJ</h4>
                        <div className="social-links">
        	 				<a href="https://www.facebook.com" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
        	 				<a href="https://www.twitter.com" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
        	 				<a href="https://www.instagram.com" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>
        	 				<a href="https://www.linkedin.com" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
        	 			</div>
        	 		</div>
        	 		<div className="footer-col footer-col-center">
        	 			<h3>Quick Links</h3>
        	 			<ul>
                         {MenuItems.map((item, index) => {
                                if(item.title === "SERVICES"){
                                    return (item.subItems.map((subItem, index2) => {
                                        return (
                                            <li key={index2}>
                                                <NavLink to={subItem.url}>
                                                    {subItem.title}
                                                </NavLink>
                                            </li>
                                        )
                                    }))
                                }else{
                                    return (
                                        <li key={index}>
                                            <NavLink to={item.url}>
                                                {item.title.toLowerCase()}
                                            </NavLink>
                                        </li>        
                                    )
                                }
                            })}
        	 			</ul>
        	 		</div>
        	 		<div className="footer-col footer-col-right">
        	 			<h3>Business Hours</h3>
        	 			<h4>Monday-Saturday 9:30am to 6:30pm <br/>
                            Sunday : closed</h4>
        	 		</div>
        	 	</div>
                <div className="row" style={{margin: "40px 0 10px 0", borderBottom: "1px solid white"}}>
                    <div className="footer-col-100">
        	 			<h2>&copy; &nbsp;Jainam Logistic Solutions &nbsp;&nbsp; {new Date().getFullYear()}</h2>
        	 		</div>
                </div>
                <div className="row">
                    <div className="footer-col-100">
        	 			<p>Developed By Sufiyan Shah</p>
                        <p>[ +91 87679 61069 ]</p>
        	 		</div>
                </div>
        	</div>
        </footer>
    );
}

export default Footer;