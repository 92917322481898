import "./Button.css";

const STYLES = [
    'btn--primary',
    'btn--outline'
];
const SIZES = [
    'btn--small',
    'btn--medium',
    'btn-large'
];

function Button({children, type, onClick, buttonStyle, buttonSize, loading}) {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle: STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize)? buttonSize: SIZES[1];
    if(loading){
        return (  
            <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type} disabled>
                {children}
            </button>
        );
    }else{
        return (  
            <button className={`btn ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
                {children}
            </button>
        );
    }
}

export default Button;