import Deliveries from '../../assets/graphics/deliveries.svg'
import "./Services.css";

function ODC() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Over Dimensional Cargo ( ODC )</h1>
                    <p className="home-text">
                    With the global need of engineering & project goods on the rise, particularly of over dimensional
                    cargo (ODC), we have the expertise & professional team to handle your in-gauge & out-gauge cargo
                    requires specialized containers like super racks, flat racks -fixed corner / open end / flush fold, open
                    top - hard top / soft top and mafi trailers.
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Deliveries} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default ODC;