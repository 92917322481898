import "./Contact.css";

function Contact() {
    return (  
        <div className="main-container">
            <div className="contact-title-container">
                <h1 className="home-title">Contact Us</h1>
            </div>
            <section className="container1">
                <div className="container-col-50">
                    <table className="home-text">
                        <tbody>
                            <tr>
                                <td><i class="fas fa-university contact-icons"></i></td>
                                <td><strong>Registered & Corporate Office</strong></td>
                            </tr> <br />
                            <tr>
                                <td><i class="fas fa-map-marker-alt contact-icons"></i></td>
                                <td>Office No 707, Prabhat Center Annexe, <br />
                                Sector 1A, CBD Belapur, Navi Mumbai 400614 <br />
                                GST Number: 27AAECJ8280C1ZJ</td>
                            </tr> <br />
                            <tr>
                                <td><i class="fas fa-envelope contact-icons"></i></td>
                                <td>Info@jainamlogistics.com</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="container-col-50 content-container1">
                    <iframe title="map" width="600" height="450" style={{border:0}} loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJlUIZskzC5zsR7j-W-OSESII&key=AIzaSyAHcUd9IaLHXEB4yXZmZ2k0zXVDR2F9DvQ"></iframe>
                </div>
            </section>
        </div>
    );
}

export default Contact;