import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";

import "./App.css";
import { Route, Routes } from "react-router";
import Footer from "./components/Footer/Footer";
import Airways from "./pages/Services/Airways";
import Seaways from "./pages/Services/Seaways";
import Roadways from "./pages/Services/Roadways";
import CustomHouse from "./pages/Services/CustomHouse";
import SupplyChain from "./pages/Services/SupplyChain";
import ProjectLogistics from "./pages/Services/ProjectLogistics";
import ODC from "./pages/Services/ODC";
import VesselChartering from "./pages/Services/VesselChartering";
import OurServices from "./pages/Services/OurServices";
import OutSourcedServices from "./pages/Services/OurSourcedServices";
import Enquiry from "./pages/Enquiry/Enquiry";

function App() {
  return (
    <>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/services/airways" element={<Airways />} />
        <Route path="/services/seaways" element={<Seaways />} />
        <Route path="/services/roadways" element={<Roadways />} />
        <Route path="/services/customs" element={<CustomHouse />} />
        <Route path="/services/supplychain" element={<SupplyChain />} />
        <Route path="/services/projectlogistics" element={<ProjectLogistics />} />
        <Route path="/services/odc" element={<ODC />} />
        <Route path="/services/vesselchartering" element={<VesselChartering />} />
        <Route path="/services/ourservices" element={<OurServices />} />
        <Route path="/services/outsourceservices" element={<OutSourcedServices />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
