import Services from '../../assets/graphics/services.svg'
import "./Services.css";

function OurServices() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Our Service Offering Includes</h1>
                    <p className="home-text">
                    • All India reach through Hub & Spoke Model <br />
                    • Fleet of dedicated containerised vehicles for safe, secure and all- weather movement <br />
                    • Consignment Level, Vehicle Level Tracking through Vehicle Tracking System (VTS). <br />
                    • End- to- end Visibility of transactions from Pick-up to Delivery to Billing through Transport Management System (TMS) portal <br />
                    • Reliable and timely movement of consignments at lower costs <br />
                    • Dedicated Key Account and Customer Service Management teams to manage <br />
                    • customer expectations and develop appropriate processes as required by keycustomers. <br />
                    • T - Hubs and branches are equipped with MHS like Forklifts, Pallets etc for damage prevention during handling <br />
                    • Packaging Solutions like Shrink Wrap, Roll Cages etc. for shrinkage and pilferage <br />
                    • prevention <br />
                    • Route planning & Optimisation <br />
                    • Trained and Uniformed Delivery staff for D2C <br />
                    • Customer Relationship Management and Cash on Delivery (COD) Service for D2C <br />
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Services} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default OurServices;