import Aircraft from '../../assets/graphics/aircraft.svg'
import "./Services.css";

function Airways() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Air-ways</h1>
                    <p className="home-text">We have an experienced team of Air Freight specialists; all are skilled in delivering your
                    daily priorities on time, confirmed space, fast frequency and competitive cost. We listen,
                    learn and form a close partnership with you to identify your needs and understand your
                    goals. Our standard Air Freight products and their customizable options bring added
                    flexibility to your supply chain management. <br /><br />
                    <strong>Express Services</strong>  <br />
                    • Express delivery of time-critical shipments<br />
                    • Delivery within a defined transit time<br />
                    • Direct service; any size or weight freight from anywhere to your desired destinations<br />
                    • Pre-clearance, documentation generation and Proof of Delivery (POD) End-to-end track and trace visibility<br /><br />
                    <strong>Standard (Consolidation) Services</strong> <br />
                    • Ideal for less urgent shipments<br />
                    • Time-definite delivery from anywhere to your desired destinations in the world Documentation generation and Customs clearance<br />
                    • Competitive rates<br />
                    • End-to-end track and trace visibility<br /><br />
                    <strong>Charter Services</strong> <br />
                    • Dedicated service desk to deal with your charter needs from anywhere<br />
                    • Dedicated transportation for door to door<br />
                    • Great flexibility with multi-options Custom clearance </p>
                    
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Aircraft} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default Airways;