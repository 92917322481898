import Logistics from '../../assets/graphics/logistics.svg'
import "./Services.css";

function CustomHouse() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Customs House Agency Management</h1>
                    <p className="home-text">
                    We have dedicated team to look after customs related work at all major airports, seaports and dry ports in India for
                    import and export cargo clearance. The team includes trained and experienced personnel having good working
                    relations with customs officials and well versed with the rules and regulations governing imports and exports into
                    and out of India, ensuring trouble free and quick documentation. Our wide experience extends to almost every type
                    of commodity segment - airfreight, sea freight, general cargo, project cargo, bulk freight, precious cargo, hazardous
                    cargo and more. <br /><br />
                    <strong>Our service offerings include:</strong> <br />
                    • Import and export customs clearance (air and ocean) Technical assistance in valuation procedures <br />
                    • Filing and cancellation of bonds <br />
                    • Technical assistance for bonded warehousing and trucking Projects Registration <br />
                    • Refund claims <br />
                    • Representation and liaison with the Customs
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Logistics} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default CustomHouse;