import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import useScrollListener from "../../hooks/useScrollListener";
import "./Navbar.css";

function Navbar() {
    let navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [navClassList, setNavClassList] = useState([]);
    const scroll = useScrollListener();
    // update classList of nav on scroll
    useEffect(() => {
      const _classList = [];
      if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
        _classList.push("nav-bar--hidden");
      setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);

    return (  
        <header id="bootstrap-overrides" className={navClassList.join(" ")}>
            <div className="container">
                <input type="checkbox" onChange={() => setChecked(!checked)} checked={checked} name="" id="check" />
                <div className="logo-container">
                    <h3 className="logo" onClick={() => navigate("/")}>Jainam Logistic Solutions</h3>
                </div>
                <div className="nav-btn">
                    <div className="nav-links">
                        <ul>
                            {MenuItems.map((item, index) => {
                                if(item.title === "SERVICES"){
                                    return (
                                        <li key={index} className="nav-link">
                                            <NavLink className="nav-link-a" to={item.url}>
                                                {item.title}
                                                <i className="fas fa-caret-down"></i>
                                            </NavLink>
                                            <div className="dropdown">
                                                <ul>
                                                    {item.subItems.map((subItem, index2) => {
                                                        return (
                                                            <li key={index2} className="dropdown-link" onClick={() => setChecked(!checked)}>
                                                                <NavLink className = "dropdown-link-a" to={subItem.url}>
                                                                    {subItem.title}
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    })}
                                                    <div className="arrow"></div>
                                                </ul>
                                            </div>
                                        </li>
                                    )
                                }else{
                                    return (
                                        <li key={index} className="nav-link" onClick={() => setChecked(!checked)}>
                                            <NavLink className = {(navData) => navData.isActive ? "nav-link-a-selected" : "nav-link-a"} to={item.url}>
                                                {item.title}
                                            </NavLink>
                                        </li>        
                                    )
                                }
                            })}
                        </ul>
                    </div>
                </div>
                <div className="hamburger-menu-container">
                    <div className="hamburger-menu">
                        <div></div>
                    </div>
                </div>
            </div>
        </header>        
    );
}

export default Navbar;