import Outsource from '../../assets/graphics/outsource.svg'
import "./Services.css";

function OutSourcedServices() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Our outsourcing services offering includes</h1>
                    <p className="home-text">
                    • Operations Process Design, Implementation & Management <br />
                    • Purchase Order (PO) Management Cargo Consolidation <br />
                    • Project Logistics <br />
                    • Customer Delivery <br />
                    • International Freight Management <br />
                    • Manpower Planning and Management <br />
                    • Distribution Management Vendor Management <br />
                    • Inventory Management <br />
                    • Customer & Supplier Order Management ERP Implementation support <br />
                    • Value Improvement Plans as operations scale-up
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Outsource} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default OutSourcedServices;