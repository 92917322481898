import Supply from '../../assets/graphics/supplychain.svg'
import "./Services.css";

function SupplyChain() {
    return (  
        <div className="main-container">
            <section className="container1">
                <div className="container-col-50">
                    <h1 className="home-title">Supply Chain Management</h1>
                    <p className="home-text">
                    As business has globalized, so have supply chains. In fact,
                    supply chain excellence has become critical to high
                    performance like never before. <br /> <br />
                    Designing a great solution is just the first step, as we
                    studies customer's supply chain processes and has
                    capabilities to Re-engineer, Build and implement
                    customised one stop Supply Chain Solutions. We have
                    developed expertise in managing supply chains across
                    sectors like Food and FMCG, Apparel & Accessories,
                    Consumer Electronics & High Tech, General
                    Merchandise, Home Furnishings & Furniture,
                    Automotive Components and Pharma. <br /> <br />
                    Our commitment to quality assurance and continuous
                    improvement keeps our team focused and effective. We
                    partner with our customers to provide effective solutions
                    which enable customers to manage variability and
                    reduce uncertainty in their logistics and supply chain.
                    With our PAN India network, we can support your
                    buying, selling & logistics processes anywhere in
                    India.This has been achieved by fully integrating Express
                    Logistics services with Technology, Automation,
                    Infrastructure, People, Processes, Know-how and
                    Network.
                    </p>
                </div>
                <div className="container-col-50 content-container1">
                    <img src={Supply} alt="about" className="container-image"/>
                </div>
            </section>
        </div>
    );
}

export default SupplyChain;